.astro-content {
    position: relative;
    display: flex;

    .astro-left {
        width: 210px;
    }

    .astro-right {
        flex-grow: 1;
        margin-left: 5px;
    }

    .astro-graph-wrap {
        width: 155px;
        height: 155px;
        margin: 0 auto;
        padding: 5px;
        box-sizing: border-box;
        cursor: default;
    }

    .astro-graph {
        position: relative;
    }

    .astro-graph-text {
        position: absolute;
        top: 34px;
        width: 100%;
        display: flex;
        color: $mainColor4;
        justify-content: space-evenly;
        
        p {
            font-size: $mainFontSize5;
            text-align: center;

            .graph-bold {
                font-weight: bold;
            }

            span {
                display: block;
            }
        }
    }

    .astro-geolocation-btn {
        position: relative;
        display: flex;
        padding: 0;
        width: 189px;
        background-color: transparent;
        color: $mainColor2;
        overflow: hidden;
        transition: all 0.2s;

        &:hover {
            transform: scale(1.05);
        }
    }

    .geo-active-text {
        margin-bottom: 5px;
        font-size: $mainFontSize4;
        line-height: 21px;
        font-weight: bold;
        color: $mainColor14;
    }

    .geolocation-text {
        padding: 0 8px;
    }

    .active-geo-text {
        font-size: $mainFontSize6;
        line-height: $mainFontSize6;
        margin-top: 1px;
    }

    .astro-geolocation {
        width: 30px;
        height: 30px;
        margin: 4px 0 4px 4px;

        .geo-stats {
            height: 100%;
            border-radius: 50%;
            cursor: pointer;

            .icon-location {
                width: 26px;
                height: 26px;
                margin: 2px;
                vertical-align: top;
            }
        }
    }

    .astro-sun {
        margin: 5px 0 21px;
        font-weight: bold;

        p {
            padding: 5px 3px;
            font-size: $mainFontSize4;
            line-height: 21px;
            color: $mainColor14;

            .icon-sun {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 13px;
            }

            .icon-sunrise {
                background-color: $colorDay;
            }

            .icon-sunset {
                background-color: $colorNight;
            }
        }

        .astro-sun-text {
            display: inline-block;
            width: 89px;
            font-weight: 500;
            text-align: left;
        }
    }
}

@media screen and (max-width: 860px) {
    .astro-content {
        padding-bottom: 21px;

        .astro-left {
            width: 45%;
        }

        .astro-graph-wrap {
            margin: 0 21px 0 auto;
        }

        .astro-right {
            padding-left: 21px;
        }
    }
}

@media screen and (max-width: 510px) {
    .astro-content {
        padding: 8px 0 21px;

        .astro-left {
            width: 40%;
            flex-shrink: 0;
            padding-top: 5px;
        }

        .astro-right {
            margin: 0 21px 0 0;
            padding: 0;
        }
        
        .astro-sun {
            margin: 5px 0 8px;

            .astro-sun-text {
                width: 72px;
            }

            p .icon-sun {
                margin-right: 8px;
            }
        }

        .astro-geolocation .geo-stats .icon-location {
            width: 18px;
            height: 18px;
        }

        .astro-geolocation-btn {
            width: 170px;
            height: 32px;
            font-size: $mainFontSize7;
            line-height: 32px;
        }

        .geolocation-text {
            padding: 0;
        }

        .geo-active-text {
            font-size: $mainFontSize6;
            margin-bottom: 0;
        }

        .astro-graph-wrap {
            width: 100px;
            height: 100px;
            margin: 0 auto;
        }

        .astro-graph-text {
            top: 25px;

            p {
                font-size: $mainFontSize7;
            }
        }

        .astro-graph {
            canvas {
                width: 89px;
                height: 89px;
            }
        }
    }
}