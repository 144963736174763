.icon {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &.icon-main {
        width: 34px;
        height: 34px;
    }

    &.icon-small {
        width: 21px;
        height: 21px;
    }
}

.icon-settings {
    background-image: url("../../../media/images/i/settings.svg");
}

.icon-add {
    background-image: url("../../../media/images/i/add.svg");
}

.icon-arrow-prev {
    background-image: url("../../../media/images/i/arrow-left.svg");
}

.icon-arrow-next {
    background-image: url("../../../media/images/i/arrow-right.svg");
}

.icon-partners {
    background-image: url("../../../media/images/i/partners.svg");
}

.icon-events-active {
    background-image: url("../../../media/images/i/events-active.svg");
}

.icon-events-past {
    background-image: url("../../../media/images/i/events-past.svg");
}

.icon-home {
    background-image: url("../../../media/images/i/home.svg");
}

.icon-calendar {
    background-image: url("../../../media/images/i/calendar.svg");
}

.icon-events {
    background-image: url("../../../media/images/i/events-active.svg");
}

.icon-contacts {
    background-image: url("../../../media/images/i/alt/mail.svg");
}

.icon-monitor {
    background-image: url("../../../media/images/i/monitor.svg");
}

.icon-mobile {
    background-image: url("../../../media/images/i/mobile.svg");
}

.icon-mail {
    background-image: url("../../../media/images/i/mail.svg");
}

.icon-phone {
    background-image: url("../../../media/images/i/phone.svg");
}

.icon-time {
    background-image: url("../../../media/images/i/time.svg");
}

.icon-social-facebook {
    background-image: url("../../../media/images/i/facebook_square.png");
}

.icon-social-linkedin {
    background-image: url("../../../media/images/i/linkedin_square.png");
}

.icon-holiday {
    background-image: url("../../../media/images/i/flag-bg.svg");
}

.icon-info {
    background-image: url("../../../media/images/i/info.svg");
}

.icon-legend {
    background-image: url("../../../media/images/i/legend.svg");
}

.icon-celebration {
    background-image: url("../../../media/images/i/celebration.svg");
}

.icon-support {
    background-image: url("../../../media/images/i/support.svg");
}

.icon-development {
    background-image: url("../../../media/images/i/development.svg");
}

.icon-application {
    background-image: url("../../../media/images/i/application.svg");
}

.icon-advertise {
    background-image: url("../../../media/images/i/advertise.svg");
}

.icon-location {
    background-image: url("../../../media/images/i/location.svg");
}

.icon-winter {
    background-image: url("../../../media/images/i/season-winter.svg");
}

.icon-spring {
    background-image: url("../../../media/images/i/season-spring.svg");
}

.icon-summer {
    background-image: url("../../../media/images/i/season-summer.svg");
}

.icon-autumn {
    background-image: url("../../../media/images/i/season-autumn.svg");
}

.icon-error {
    background-image: url("../../../media/images/i/error.svg");
}

/* icons zodiac */
.icon-aquarius { background-image: url("../../../media/images/i/zodiac/icon-aquarius.png"); }
.icon-pisces { background-image: url("../../../media/images/i/zodiac/icon-pisces.png"); }
.icon-aries { background-image: url("../../../media/images/i/zodiac/icon-aries.png"); }
.icon-taurus { background-image: url("../../../media/images/i/zodiac/icon-taurus.png"); }
.icon-gemini { background-image: url("../../../media/images/i/zodiac/icon-gemini.png"); }
.icon-cancer { background-image: url("../../../media/images/i/zodiac/icon-cancer.png"); }
.icon-leo { background-image: url("../../../media/images/i/zodiac/icon-leo.png"); }
.icon-virgo { background-image: url("../../../media/images/i/zodiac/icon-virgo.png"); }
.icon-libra { background-image: url("../../../media/images/i/zodiac/icon-libra.png"); }
.icon-scorpio { background-image: url("../../../media/images/i/zodiac/icon-scorpio.png"); }
.icon-sagittarius { background-image: url("../../../media/images/i/zodiac/icon-sagittarius.png"); }
.icon-capricorn { background-image: url("../../../media/images/i/zodiac/icon-capricorn.png"); }

.theme-light {
    .icon-theme::before {
        background-image: url("../../../media/images/i/theme-light.svg");
    }
}

.theme-dark {
    .icon-theme::before {
        background-image: url("../../../media/images/i/theme-dark.svg");
    }
}

.icon-moon {
    display: block;
    width: 26px;
    height: 26px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #000;

    &.moon-full-moon {
        background-color: #F5E8B1;
    }

    &.moon-new-moon {
        background-color: #000;
    }

    &.moon-first-quarter {
        background-color: #F5E8B1;

        &::after {
            content: '';
            display: block;
            width: 50%;
            height: 100%;
            background-color: #000;
        }
    }

    &.moon-last-quarter {
        background-color: #000;

        &::after {
            content: '';
            display: block;
            width: 50%;
            height: 100%;
            background-color: #F5E8B1;
        }
    }
}