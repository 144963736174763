.event-info-wrap {
    width: 100%;
    min-height: 377px;
    background-color: $mainColor10;
    overflow-y: auto;

    .event-page-content {
        max-width: $largeScreenWidth;
        margin: 0 auto;
        padding: 34px 55px;
        display: flex;
        column-gap: 55px;
    }

    .event-data-content {
        flex-grow: 1;
        word-break: break-all;
        box-sizing: border-box;
        overflow-y: auto;

        .event-data-head {
            position: relative;
            display: flex;
        }
    
        .event-data-media {
            flex-basis: 610px;
            flex-grow: 0;
            flex-shrink: 0;
            height: 377px;
            background-color: #fff;

            .event-media {
                display: block;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }

        .event-data-param-wrap {
            width: 100%;
            background-color: $mainColor14;
            color: #fff;
            overflow: hidden;
        }

        .event-data-param {
            text-align: center;
            padding: 21px 13px 0;
            box-sizing: border-box;
            position: relative;
        }

        .event-param-title {
            color: $mainColor1;
            font-size: $mainFontSize3;
        }

        .event-param-text {
            margin-top: 5px;
            font-size: $mainFontSize4;
            max-height: 75px;
            overflow: hidden;
        }

        .event-data-category {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 13px;

            span {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0 21px;
                height: 55px;
                line-height: 55px;
                font-size: $mainFontSize3;
                color: #fff;
            }
        }

        .event-data-body {
            overflow-y: auto;
        }

        .event-data-title {
            font-size: $mainFontSize2;
            letter-spacing: 1px;
            margin: 21px;
            color: $mainColor11;
            word-break: break-word;
            font-weight: bold;
        }

        .event-data-description {
            margin: 21px;
            font-size: $mainFontSize4;
        }

        .event-data-source {
            .main-button {
                display: block;
                margin: 34px auto;
            }
        }

        .event-data-map {
            .event-map-iframe {
                border: 0;
                box-sizing: border-box;
            }
        }
    }

    .sidebar {
        flex-basis: 340px;

        .sidebar-event-info {
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 1390px) {
    .event-info-wrap {
        .event-page-content {
            flex-direction: column;
            width: 885px;
            padding: 34px;

            .sidebar {
                flex-grow: 1;
                flex-basis: auto;
                margin-top: 34px;
            }
        }
    }
}

@media screen and (max-width: 970px) {
    .event-info-wrap {
        .event-page-content {
            width: 100%;
            padding: 0;
        }

        .main-box {
            border-radius: 0;
        }
    }
}

// @media screen and (max-width: 860px) {
//     .event-info-wrap {
//         .event-data-content {
//             .event-data-media {
//                 flex-basis: 377px;
//                 height: 210px;
//             }

//             .event-data-param {
//                 padding: 13px 21px 0;
//                 display: flex;
//                 text-align: left;
//                 column-gap: 13px;
//             }

//             .event-param-title {
//                 flex: 0 0 55px;
//             }

//             .event-param-text {
//                 max-height: 25px;
//             }

//             .event-data-category {
//                 height: 8px;

//                 span {
//                     height: 34px;
//                     line-height: 34px;
//                     font-size: $mainFontSize4;
//                 }
//             }
//         }
//     }
// }