.tooltip-wrap {
    position: absolute;
    left: 0;
    top: -121px;
    width: 188px;
    cursor: default;

    .close-button {
        top: 8px;
        right: 8px;
        
        .close-btn {
            margin: 0;
        }
    }

    .tooltip-content {
        height: 111px;
        padding: 8px 13px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: $mainColor4;
        color: #000;
        text-align: left;

        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            left: 11px;
            bottom: -8px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $mainColor4;
        }
    }

    .tooltip-text {
        width: 100%;
        overflow-y: hidden;
        font-size: $mainFontSize5;
        line-height: 1.5rem;

        a {
            color: $mainColor1;
            text-decoration: underline;
        }
    }

    .main-button {
        height: 34px;
        line-height: 34px;
        padding: 0 13px;
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
}