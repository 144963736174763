/* HEADER */
.home-page {
    .main-content {
        margin-top: 0;
    }
}

.main-content {
    margin-top: 89px;
}

#site-header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 360px;
    height: 89px;
    z-index: 5;
    transition: all 0.3s;
}

.header-wrap {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-shadow: $mainBoxShadow1;
}

.header-inner {
    margin: 0 auto;
    max-width: $largeScreenWidth;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-family: $mainFont2;

    .h-logo {
        display: flex;
        margin-left: 21px;
        padding: 22px 0 0 0;
        height: 44px;
        transition: all 0.3s;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        #main-logo {
            width: 200px;
            height: 44px;
            font-size: 0;
            background-image: url("../../../media/images/chronos-logo-text.svg");
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.3s;
        }

        .beta-text {
            height: 18px;
            margin-left: 13px;
            padding: 3px 8px;
            border-radius: 5px;
            background-color: $mainColor1;
            color: #fff;
            font-size: $mainFontSize5;
            line-height: 18px;
            letter-spacing: 1px;
            font-weight: bold;
        }
    }

    .main-menu {
        padding: 34px 0 21px 0;
        height: 34px;
        display: block;
        transition: all 0.3s;

        .menu-options {
            display: flex;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            .option-m {
                padding: 0 25px 0 25px;
                height: 100%;
                transition: all 0.3s;
                list-style-type: none;

                &.option-mobile-theme {
                    display: none;
                }
            }

            .icon {
                display: none;
            }
        }
    }

    .h-user {
        margin-right: 21px;
        line-height: 34px;
        display: flex;
        transition: all 0.3s;

        .h-login-btn {
            margin-top: 24px;
            padding: 0 21px;
            display: block;
            transition: all 0.3s;
        }

        .h-login-text {
            font-size: $mainFontSize5;
            color: #fff;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .h-user-theme {
            margin-top: 22px;
            margin-right: 21px;
            transition: all 0.3s;
        }

        .theme-text {
            display: block;
            font-size: 13px;
            text-align: center;
            line-height: 13px;
        }
    }

    .theme-switcher {
        position: relative;
        display: block;
        width: 46px;
        height: 25px;
        margin: 4px 0;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .theme-slider {
                background-color: $mainColor6;
            }

            &:checked + .theme-slider::before {
                -webkit-transform: translateX(20px);
                -ms-transform: translateX(20px);
                transform: translateX(20px);
            }
        }
    }

    .theme-slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 13px;
        transition: 0.4s;
        cursor: pointer;

        &::before {
            position: absolute;
            content: "";
            height: 21px;
            width: 21px;
            left: 2px;
            bottom: 2px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            transition: 0.4s;
        }
    }

    .om-text {
        margin: 0;
        padding: 0;
        width: 100%;
        font-size: $mainFontSize5;
        text-align: center;
        font-weight: 500;
        letter-spacing: 1px;
      
        &::after {
            content: '';
            margin: 0 0 0 auto;
            padding: 0;
            display: block;
            width: 0;
            height: 2px;
            background-color: $mainColor2;
            transition: all 0.3s;
        }
      
        &:hover::after {
            margin: 0 auto 0 0;
            width: 50%;
            padding-left: 50%;
        }
    
        &.om-active {
            margin: 0;
            color: $mainColor2;
            font-weight: 700;
        
            &:hover::after {
                display: none;
            }
        }
    }
}

.page-link {
    text-decoration: none;
}

#site-header.header-mini {
    height: 44px;

    .header-inner {
        .h-logo {
            padding-top: 7px; 
            height: 30px;

            #main-logo {
                width: 137px;
            }
        }

        .main-menu {
            padding: 11px 0 10px 0;
            height: 23px;

            .option-m {
                padding: 0 21px 0 21px;
            }
        }

        .h-user {
            padding: 0;

            .h-user-theme {
                margin-top: 2px;
            }

            .theme-switcher {
                margin: 2px 0;
            }

            .h-login-btn {
                margin-top: 0;
                height: 44px;
                line-height: 42px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .main-menu .menu-options {
        display: block;
    }

    .menu-hamburger {
        display: none;
    }

    .header-inner {
        display: flex;

        .h-logo {
            order: 1;
        }

        .main-menu {
            order: 2;
        }

        .h-user {
            order: 3;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header-inner {
        .h-logo {
            position: relative;
            margin-left: 0;
            order: 2;

            .beta-text {
                position: absolute;
                right: 0;
                top: 7px;
                margin: 0;
                padding: 0 11px;
                font-size: $mainFontSize7;
                font-weight: normal;
            }
        }

        .main-menu {
            order: 1;
            width: 89px;
            
            .menu-options {
                display: none;

                .option-m.option-mobile-theme {
                    display: flex;
                    align-items: center;
                    border-top: 1px solid $mainColor4;

                    .theme-text {
                        padding-left: 21px;
                        font-size: $mainFontSize5;
                        font-weight: 500;
                        text-transform: uppercase;
                    }

                    .theme-switcher {
                        align-self: flex-end;
                    }
                }
            }
        }

        .h-user {
            order: 3;
            text-align: center;

            .h-user-theme {
                display: none;
            }
        }

        .om-text::after {
            display: none;
        }
    }

    /* HAMBURGER MENU */
    .menu-hamburger {
        display: block;
        width: 34px;
        margin: 0 auto;
        cursor: pointer;

        .ham-arrows {
            width: 34px;
            height: 4px;
            background-color: #000;
            margin-bottom: 7px;
            border-radius: 2px;
            position: relative;
            -webkit-transition: 0.34s ease-in-out;
            -moz-transition: 0.34s ease-in-out;
            -o-transition: 0.34s ease-in-out;
            transition: 0.34s ease-in-out;
            top: 0;
        }
    }

    .main-menu.menu-active {
        .menu-options {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            height: auto;
            width: 100%;
            margin-top: 21px;
            padding: 21px 0 0;
            text-align: center;
            background-color: $mainColor8;
            overflow-y: auto;
            z-index: 9;
            -webkit-animation: menuAppear 0.55s;
            animation: menuAppear 0.55s;

            .option-m {
                padding: 21px 34px;
                text-align: left;
            }

            .icon {
                display: inline-block;
                margin-right: 21px;
                vertical-align: top;
            }
        }

        .ham-arrow-1 {
            transform: rotate(45deg);
            top: 11px;
        }

        .ham-arrow-2 {
            opacity: 0;
        }

        .ham-arrow-3 {
            transform: rotate(-45deg);
            top: -10px;
        }
    }

    #site-header.header-mini .header-inner .main-menu {
        padding: 11px 0 0 0;

        .menu-options {
            margin-top: 6px;
        }

        .ham-arrows {
            height: 3px;
            margin-bottom: 6px;
        }

        &.menu-active {
            .ham-arrow-1 {
                top: 9px;
            }

            .ham-arrow-3 {
                top: -9px;
            }
        }

        .option-m {
            padding: 21px 34px;
        }
    }


}

@media screen and (max-width: 860px) {
    .main-content {
        margin-top: 55px;
    }

    #site-header {
        height: 55px;
    }

    .header-inner {
        .h-logo {
            height: 30px;
            padding-top: 11px;
            flex-direction: column;
            text-align: center;
    
            #main-logo {
                width: 137px;
                height: 30px;
            }

            .beta-text {
                top: auto;
                bottom: -11px;
                height: 14px;
                line-height: 14px;
                padding: 0 5px;
                font-size: $mainFontSize8;
                z-index: 1;
            }
        }

        .main-menu {
            padding: 17px 0 4px 0;
        }

        .h-user {
            .h-login-btn {
                margin-top: 8px
            }

            .h-login-text {
                text-transform: capitalize;
            }
        }
    }

    .menu-hamburger .ham-arrows {
        height: 2px;
        width: 28px;
        border-radius: 0;
    }
    
    .main-menu.menu-active {
        .menu-options {
            margin-top: 11px;
        }

        .ham-arrow-1 {
            top: 8px;
        }
    }
}

@media screen and (max-width: 540px) {
    .header-inner {
        .h-user {
            margin-right: 13px;
        }

        .main-menu {
            width: 60px;
        }
    }
}

@-webkit-keyframes menuAppear {
    0% {
        left: -233px;
    }
    100% {
        left: 0;
    }
}

@keyframes menuAppear {
    0% {
        left: -233px;
    }
    100% {
        left: 0;
    }
}