/* EVENTS STYLES */
.events-wrap {
    width: 100%;
    min-height: 377px;
    background-color: $mainColor3;
}

.events-react {

    /* Events Filter */
    .events-filter-wrap {
        border-top: 1px solid rgba(0, 0, 0, 0.4);
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        background-color: rgba(187, 187, 187, 0.2);
    }

    .events-filter-form {
        position: relative;
        max-width: $largeScreenWidth;
        margin: 0 auto;
        padding: 8px 21px;
        box-sizing: border-box;

        .events-filter-list {
            display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox;      /* TWEENER - IE 10 */
            display: -webkit-flex;     /* NEW - Chrome */
            display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
        }

        .events-filter-options-input {
            display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox;      /* TWEENER - IE 10 */
            display: -webkit-flex;     /* NEW - Chrome */
            display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
            flex-flow: row wrap;
        }

        .events-filter-option {
            padding: 0 21px;
            position: relative;
            font-size: $mainFontSize6;

            .events-filter-option-selector {
                margin-left: 8px;
                font-family: inherit;
                font-size: inherit;
                background-color: transparent;
                cursor: pointer;
    
                &:hover {
                    background-color: rgba(187, 187, 187, 0.2);
                    border: 1px solid rgba(0, 0, 0, 0.4);
                    border-radius: 3px;
                }
            }

            .events-filter-option-label {
                pointer-events: none;
            }
        }

        .events-dropdown-arrow {
            position: relative;
            padding: 4px 29px 4px 13px;
            border: 1px solid transparent;
            line-height: 25px;
            outline: none;

            &::after {
                border: 4px solid transparent;
                border-top-color: #111;
                content: "";
                height: 0;
                position: absolute;
                right: 8px;
                top: 15px;
                width: 0;
            } 

            &.events-dropdown-arrow-active {
                border: 1px solid rgba(0, 0, 0, 0.4);
                box-shadow: none;
            }
        }

        .events-filter-dropdown-el {
            display: none;
            position: absolute;
            left: 29px;
            top: 34px;
            background-color: $mainColor6;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            width: 250px;

            &.events-filter-dropdown-el-active {
                display: block;
                z-index: 3;
            }

            .events-filter-dropdown-el-content {
                padding-bottom: 8px;
            }

            .events-filter-dropdown-search-wrap {
                padding: 8px;
                position: relative;

                input {
                    width: 100%;
                    padding: 1px 24px 1px 5px;
                    box-sizing: border-box;
                    line-height: 26px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    -webkit-box-shadow: $mainBoxShadow2;
                    -moz-box-shadow: $mainBoxShadow2;
                    box-shadow: $mainBoxShadow2;
                }

                .dropdown-search-icon {
                    position: absolute;
                    right: 13px;
                    top: 13px;
                    width: 21px;
                    height: 21px;

                    &::before {
                        content: "";
                        display: block;
                        height: 100%;
                        background-image: url("../../../../../media/images/i/magnifier.svg");
                        background-size: contain;
                    }
                }
            }

            .events-filter-dropdown-options-wrap {
                max-height: 270px;
                overflow-y: auto;
                overflow-x: hidden;
                clear: both;
            }

            .events-filter-dropdown-option {
                padding: 0;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -ms-box-sizing: border-box;
                box-sizing: border-box;
                position: relative;

                &:hover {
                    background-color: #d7e5f3;
                }

                &.events-filter-dropdown-option-all {
                    margin-bottom: 5px;
                    padding: 5px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    
                    label {
                        padding: 8px 8px 8px 34px;
                    }
                }

                label {
                    display: block;
                    padding: 5px 8px 5px 34px;
                    cursor: pointer;
                }

                input[type="checkbox"] {
                    position: absolute;
                    left: 8px;
                }
            }

            .dropdown-option-item-hidden {
                display: none;
            }
        }

        // .events-filter-search {
        //     position: relative;
        //     opacity: 0.7;

        //     &:hover {
        //         opacity: 1;
        //     }

        //     input.events-filter-search-input {
        //         padding: 1px 24px 1px 5px;
        //         -moz-box-sizing: border-box;
        //         -webkit-box-sizing: border-box;
        //         -ms-box-sizing: border-box;
        //         box-sizing: border-box;
        //         line-height: 21px;
        //         border: 1px solid #ccc;
        //         border-radius: 3px;
        //         -webkit-box-shadow: $mainBoxShadow2;
        //         -moz-box-shadow: $mainBoxShadow2;
        //         box-shadow: $mainBoxShadow2;
        //     }

        //     .filter-search-icon {
        //         position: absolute;
        //         right: 2px;
        //         top: 2px;
        //         width: 21px;
        //         height: 21px;
                
        //         &::before {
        //             content: "";
        //             display: block;
        //             height: 100%;
        //             background-image: url("../../../../../media/images/i/magnifier.svg");
        //             background-size: contain;
        //         }
        //     }
        // }
    }

    /* Events Content */
    .events-content {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 55px 21px;
        max-width: $largeScreenWidth;
        min-height: 377px;
        box-sizing: border-box;
        overflow-y: auto;

        &::after {
            content: "";
            flex: auto;
            max-width: 377px;
            min-width: 292px;
        }
    }

    .events-item {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        width: 377px;
        margin: 0 0 55px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all .3s ease-in-out;

        .ev-img-wrap {
            height: 233px;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            
            .event-media {
                display: block;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: .3s ease-in-out;
            }
        }

        .event-category {
            width: 100%;
            height: 8px;
            position: absolute;
            bottom: 0;
            left: 0;
            
            h3 {
                display: inline-block;
                margin: 0;
                padding: 0 18px;
                height: 34px;
                line-height: 34px;
                position: absolute;
                bottom: 0;
                color: #fff;
                text-align: center;
                font-size: $mainFontSize4;
            }
        }

        .ev-info-wrap {
            padding: 13px;
            height: 133px;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            background-color: $mainColor10;
            //box-shadow: $mainBoxShadow1;

            .event-text {
                margin: 0 0 13px;
            }

            .event-title {
                overflow: hidden;
                font-size: $mainFontSize4;
                font-weight: bold;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .event-where {
                width: 62%;

                span {
                    display: block;
                    font-size: $mainFontSize3;
                    text-transform: capitalize;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .event-city {
                color: $mainColor7;
            }

            .event-place {
                color: $mainColor12;
            }

            .date-box {
                bottom: 8px;
                top: initial;
            }
        }

        &:hover {
            transform: scale(1.06, 1.06);

            .ev-img-wrap .event-media {
                transform: scale(1.1);
            }

            .ev-info-wrap {
                background-color: #f2f2f2;
            }
        }
    }

    .loader-wrap {
        margin: 34px;
    }
}

.events-no-item {
    margin: 55px;

    .icon-error {
        display: block;
        margin: 0 auto 34px;
        width: 89px;
        height: 89px;
    }

    p {
        text-align: center;
        font-size: $mainFontSize4;
    }
}

@media screen and (max-width: 1250px) {
    .events-react {
        .events-content {
            justify-content: space-evenly;

            &::after {
                display: none;
            }
        }
        
        .events-item {
            margin: 0 13px 34px;
        }
    }
}

@media screen and (max-width: 860px) {
    .page-title {
        margin-bottom: 0;

        h1 {
            padding: 13px 0 13px 0;
            font-size: $mainFontSize3;

            &::after {
                display: none;
                padding-top: 5px;
            }
        }
    }

    .events-react {
        .events-content {
            padding: 34px 21px;
            flex-flow: column;
        }

        .events-filter-form {
            .events-filter-option {
                padding: 0 13px;
            }
        }

        .events-item {
            width: 100%;
            display: flex;

            .ev-info-wrap {
                flex: 1 1;

                .event-title {
                    white-space: wrap;
                }
            }

            .ev-img-wrap {
                width: 133px;
                height: 133px;
            }
        }
    }
}

@media screen and (max-width: $mobileScreenWidth) {
    .events-react {
        .events-filter-form {
            .events-filter-options-input {
                flex-flow: column wrap;
                margin: 0 auto;
                text-align: center;
            }
            
            .events-filter-option {
                flex: 1;
                margin: 5px 0;
            }
        }
    }
}
