.admin-wrap {

}

.admin-head {
    margin: 0 auto;
    padding: 0 34px;
    width: 100%;
    max-width: $largeScreenWidth;
    box-sizing: border-box;
    overflow-y: auto;

    a {
        margin: 13px 0 0;
        display: block;
        width: 144px;
        height: 89px;
        line-height: 89px;
        font-size: $mainFontSize2;
        text-transform: uppercase;
        background-color: $mainColor2;
        color: #fff;
        text-align: center;
    }
}

.admin-content {
    margin: 0 auto;
    padding: 34px;
    max-width: $largeScreenWidth;
    width: 100%;
    box-sizing: border-box;

    .admin-home {

    }

    .admin-home-link {
        width: 50%;
        display: inline-block;
        text-align: center;
        font-size: $mainFontSize4;
        box-sizing: border-box;
        padding: 21px;

        a {
            display: block;
            width: 100%;
            height: 144px;
            line-height: 144px;
            text-transform: uppercase;
            border: 1px solid #000;
        }
    }

    .admin-events-list {

    }

    .admin-events-item {
        margin-bottom: 21px;
        padding: 13px;
        border: 1px solid #000;
        display: flex;
        justify-content: space-between;
    }

    .admin-events-item-options {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
    }

    .admin-event-option {
        padding: 0;
        border: 0;
        width: 89px;
    }

    .admin-event-option-edit {
        background-color: $mainColor7;
    }

    .admin-event-option-delete {
        background-color: $colorNegative;
        margin-left: 13px;
    }
}

/* ===== ADMIN LOGIN ===== */
.admin-login-wrap {
    background-color: $mainColor7;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // overflow-y: auto;
}

.admin-login-content {
    margin: 233px auto 0;
    padding: 34px;
    width: 377px;
    box-sizing: border-box;
    background-color: $mainColor4;

    .admin-login-head {
        margin-bottom: 34px;

        h4 {
            font-size: $mainFontSize4;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .admin-login-input {
        margin-bottom: 21px;

        label {
            display: block;
            width: 144px;
            font-size: $mainFontSize5;
            margin-bottom: 8px;
        }
    
        input {
            border: 1px solid #000;
            width: 100%;
        }
    }

    .admin-login-button {
        margin-top: 34px;
        
        button {
            width: 100%;
        }
    }
}