#section-summary {
    padding: 34px 55px 89px;
    background-color: #fff; //$mainColor6; //$mainColor10;
    overflow: auto;

    .text-logo {
        width: 153px;
        height: 34px;
    }
}

.summary-content {
    overflow: auto;
    //background-color: #fff; //$mainColor10;
    //background: linear-gradient(to right, $mainColor6, $mainColor4, $mainColor6);

    .summary-events {
        height: 420px;
        margin-top: 21px;
        display: flex;

        // &::before,
        // &::after { 
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     pointer-events: none;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        // }

        // &::before {
        //     left: 0;
        //     width: 273px;
        //     background-image: url("../../../../media/images/test-2.png");
        //     background-position: left top;
        // }

        // &::after {
        //     right: 0;
        //     width: 274px;
        //     background-image: url("../../../../media/images/test-1.png");
        //     background-position: right top;
        // }

        .summary-events-info {
            width: 40%;
        }
    
        .summary-events-scene {
            width: 60%;
            position: relative;
        }

        .summary-events-text {
            margin-right: 0;
            margin-left: auto;
            width: 377px;
            padding-top: 34px;
            color: $mainColor11;

            h3 {
                font-size: $mainFontSize1;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            p {
                margin-top: 13px;
                font-size: $mainFontSize3;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .scene-holder {
            margin-top: 34px;
        }

        .scene {
            height: 255px;
            max-width: 610px;
            width: 100%;
            margin: 0 auto;
            background-image: url("../../../../media/images/scene.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            overflow: auto;
        }

        .scene-screen {
            width: 515px;
            height: 204px;
            margin: 0 auto;
            box-sizing: border-box;
            background-color: #fff;
        }

        .event-map-iframe {
            margin: 0;
            width: 100%;
        }

        .people-holder {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 55%;
            overflow: hidden;
            pointer-events: none;
            // background-size: cover;
            // background-repeat: no-repeat;
            // background-position: left top;
        }

        .human-icon {
            position: absolute;
            height: 55px;
            width: 21px;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 0;
    
            animation-name: showHuman;
            animation-duration: 0.5s;
            animation-timing-function: linear;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        .human-icon-active {
            visibility: visible;
        }

        .human-icon-1 { background-image: url("../../../../media/images/i/animate/human-1.svg"); }
        .human-icon-2 { background-image: url("../../../../media/images/i/animate/human-2.svg"); }
        .human-icon-3 { background-image: url("../../../../media/images/i/animate/human-3.svg"); }
        .human-icon-4 { background-image: url("../../../../media/images/i/animate/human-4.svg"); }
        .human-icon-5 { background-image: url("../../../../media/images/i/animate/human-5.svg"); }
        .human-icon-6 { background-image: url("../../../../media/images/i/animate/human-6.svg"); }
        .human-icon-7 { background-image: url("../../../../media/images/i/animate/human-7.svg"); }
        .human-icon-8 { background-image: url("../../../../media/images/i/animate/human-8.svg"); }
    
        .human-pos-1 {left: 39%; top: 45%; animation-delay: 0.3s;}
        .human-pos-2 {left: 8%; top: 42%; animation-delay: 0.5s;}
        .human-pos-3 {left: 27%; top: 41%; animation-delay: 0.8s;}
        .human-pos-4 {left: 74%; top: 49%; animation-delay: 1.3s;}
        .human-pos-5 {left: 95%; top: 48%; animation-delay: 2.1s;}
        .human-pos-6 {left: 67%; top: 52%; animation-delay: 2.5s;}
        .human-pos-7 {left: 14%; top: 49%; animation-delay: 3.0s;}
        .human-pos-8 {left: 44%; top: 50%; animation-delay: 3.4s;}
        .human-pos-9 {left: 32%; top: 42%; animation-delay: 3.8s;}
        .human-pos-10 {left: 85%; top: 52%; animation-delay: 4.2s;}
        .human-pos-11 {left: 79%; top: 57%; animation-delay: 4.8s;}
        .human-pos-12 {left: 54%; top: 51%; animation-delay: 5.5s;}
        .human-pos-13 {left: 35%; top: 55%; animation-delay: 5.8s;}
        .human-pos-14 {left: 91%; top: 59%; animation-delay: 6.4s;}
        .human-pos-15 {left: 61%; top: 56%; animation-delay: 6.9s;}
        .human-pos-16 {left: 73%; top: 67%; animation-delay: 7.2s;}
        .human-pos-17 {left: 21%; top: 47%; animation-delay: 7.7s;}
        .human-pos-18 {left: 49%; top: 59%; animation-delay: 8.5s;}
        .human-pos-19 {left: 83%; top: 70%; animation-delay: 8.8s;}
        .human-pos-20 {left: 26%; top: 62%; animation-delay: 8.9s;}
        .human-pos-21 {left: 1%; top: 52%; animation-delay: 9.4s;}
        .human-pos-22 {left: 9%; top: 64%; animation-delay: 9.9s;}
        .human-pos-23 {left: 40%; top: 67%; animation-delay: 10.3s;}
        .human-pos-24 {left: 31%; top: 55%; animation-delay: 10.8s;}
        .human-pos-25 {left: 17%; top: 64%; animation-delay: 11.1s;}
        .human-pos-26 {left: 55%; top: 66%; animation-delay: 11.5;}
        .human-pos-27 {left: 21%; top: 75%; animation-delay: 12.1s;}
        .human-pos-28 {left: 47%; top: 76%; animation-delay: 12.4s;}
        .human-pos-29 {left: 58%; top: 81%; animation-delay: 12.9s;}
        .human-pos-30 {left: 78%; top: 79%; animation-delay: 13.3s;}
        .human-pos-31 {left: 31%; top: 75%; animation-delay: 13.8s;}
        .human-pos-32 {left: 64%; top: 73%; animation-delay: 14.4s;}
        .human-pos-33 {left: 2%; top: 70%; animation-delay: 15s;}
        .human-pos-34 {left: 92%; top: 75%; animation-delay: 16s;}
    }
}

@keyframes showHuman {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media screen and (max-width: 1024px) {
    .summary-content .summary-events {
        .scene-holder {
            margin-top: 0;
        }

        .scene {
            width: 440px;
            height: 255px;
        }

        .scene-screen {
            width: 377px;
            height: 150px;
            margin-top: 34px;
        }

        .people-holder {
            height: 89%;
        }

        .summary-events-text {
            width: auto;
        }
    }
}

@media screen and (max-width: 860px) {
    #section-summary {
        .section-title {
            display: none;
        }
    }

    .summary-content .summary-events {
        flex-direction: column;

        .summary-events-info {
            width: 100%;
        }

        .summary-events-text {
            padding-top: 0;
            text-align: center;

            h3 {
                width: 60%;
                margin: 0 auto;
                font-size: $mainFontSize2;
            }
        }

        .summary-events-scene {
            width: 100%;
            height: 377px;
            margin-top: 21px;
        }
    }
}

@media screen and (max-width: 860px) {
    .summary-content .summary-events {
        .summary-events-scene {
            margin-top: 0;
        }

        .summary-events-text h3 {
            width: 80%;
        }

        .scene {
            width: 344px;
            height: 208px;
        }

        .scene-screen {
            width: 288px;
            height: 114px;
        }
    }
}

@media screen and (max-width: 460px) {
    #section-summary {
        padding-bottom: 55px;
    }

    .summary-content .summary-events {
        margin-top: 0;

        .summary-events-text h3 {
            font-size: $mainFontSize3;
        }

        .scene {
            width: 250px;
        }

        .scene-screen {
            width: 233px;
            height: 103px;
        }
    }
}