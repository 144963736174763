body {
    font-family: $mainFont1;

    ::-webkit-scrollbar {
        width: 13px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $mainColor4;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $mainColor2;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $mainColor2Hover;
    }

}

.content-wrap {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    min-width: $minScreenWidth;
    background: none;
    zoom: 1;
}

#root {
    height: 100%;
    margin: 0;
    overflow-y: auto;
}

.main-content {
    background-color: #fff;
    overflow-y: auto;
}
  
.page-title {
    margin: 0 auto;
    max-width: $largeScreenWidth;
    text-align: center;
    margin-bottom: 34px;
    text-transform: uppercase;

    h1 {
        margin: 0;
        padding: 34px 0 13px 0;
        font-family: $mainFont2;
        font-weight: 500;
        font-size: $mainFontSize2;
        line-height: 55px;
        display: inline-block;
        letter-spacing: 3px;

        &::after {
            margin: 0 auto;
            padding-top: 21px;
            content: '';
            display: block;
            width: 233px;
            border-bottom: 1px solid #000000;
        }
    }
}

.section-title {
    margin: 0 auto 34px;
    max-width: $largeScreenWidth;
    text-align: center;

    h2 {
        margin: 0;
        padding: 21px 0 8px 0;
        font-family: $mainFont2;
        font-weight: bold;
        font-size: $mainFontSize2;
        line-height: 34px;
        display: inline-block;
        letter-spacing: 2px;
        color: $mainColor11;

        &::after {
            margin: 0 auto;
            padding-top: 21px;
            content: '';
            display: block;
            width: 89px;
            border-bottom: 1px solid $mainColor11;
        }
    }
}

.secondary-title {
    max-width: $largeScreenWidth;
    margin-bottom: 13px;

    h3 {
        margin: 0;
        padding: 13px 0 5px 0;
        font-family: $mainFont2;
        font-weight: 500;
        font-size: $mainFontSize2;
        line-height: 34px;
        display: inline-block;
        letter-spacing: 1px;

        &::after {
            margin: 0;
            padding-top: 13px;
            content: '';
            display: block;
            width: 89px;
            border-bottom: 1px solid #000000;
        }
    }
}

.box-title {
    width: fit-content;
    padding: 21px;

    h3 {
        padding-bottom: 8px;
        padding-right: 34px;
        color: $mainColor5;
        border-bottom: 1px solid $mainColor5;
        font-size: $mainFontSize3;
    }
}

.text-logo {
    display: inline-block;
    font-size: 0;
    background-image: url("../../media/images/chronos-logo-text.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 95px;
    height: 21px;
    vertical-align: top;
}

.date-box {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 10px 21px 6px;
    background-color: $mainColor1;
    color: #fff;

    span {
        display: block;
        line-height: 1;
        text-transform: uppercase;
        font-weight: bold;
    }

    .weekday-text {
        font-size: $mainFontSize5;
    }

    .date-text {
        font-size: $mainFontSize3;
    }
}

.main-button {
    display: block;
    width: fit-content;
    padding: 0 34px;
    border: 1px solid $mainColor2;
    background-color: $mainColor2;
    color: #fff;
    height: 40px;
    font-size: $mainFontSize5;
    line-height: 38px;
    font-family: $mainFont2;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background 0.2s;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
        border-color: $mainColor2Hover;
        background-color: $mainColor2Hover;
    }

    &.sub-button {
        background-color: $mainColor1;
        border-color: $mainColor1;

        &:hover {
            background-color: darken($mainColor1, 8%);
        }
    }

    &.alt-button {
        background-color: #fff;
        border: transparent;
        color: $mainColor2;

        &:hover {
            background-color: $mainColor1;
            color: #fff;
        }
    }

    &.disabled-button {
        background-color: $mainColor5;
        border-color: $mainColor5;
        cursor: default;
    }
}

.main-input {
    height: 40px;
    border: none;
    background-color: #fff;
    padding: 8px;
    box-sizing: border-box;
    font-size: $mainFontSize5;
    line-height: 24px;

    &:focus {
        outline: none;
    }
}

.main-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: $mainBoxShadow1;
    position: relative;
}

.popup-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 8;
    cursor: default;

    .popup-content {
        margin: 5% auto 0;
        padding: 21px;
        max-width: 640px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
    }

    .popup-title {
        text-align: center;
        font-size: $mainFontSize2;
    }
}

.text-orange {
    color: $mainColor1;
}

.appear-scroll {
    opacity: 0;
    transition: opacity 1.3s;

    &.scrolled {
        opacity: 1;
    }
}

/* --- FAQUESTIONS PAGE --- */
.faquestions-wrap {
    background-color: $mainColor8;
}
/* --- END FAQUESTIONS PAGE --- */

@media screen and (max-width: 680px) {
    .popup-wrap .popup-content {
        margin: 5% 21px 0;
        width: auto;
        min-width: 300px;
    }
}