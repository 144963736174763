#section-intro {
    padding-top: 89px;
    background-color: rgba($mainColor2, 0.21); //$mainColor4;
    overflow: auto;
    // position: relative;
    // background-image: url("../../../../media/images/background/subscribers.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-attachment: fixed;
    // background-position: 34% center;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background-color: rgba(255, 255, 255, 0.62);
    //     z-index: 0;
    // }
}

.intro-content {
    display: flex;
    gap: 34px 55px;
    padding: 34px 55px;
    max-width: $largeScreenWidth;
    margin: 0 auto;
    // position: relative;
    // z-index: 1;

    .intro-calendar {
        flex-grow: 1; 

        .intro-calendar-head {
            h3 {
                padding: 0 21px 21px;
                font-size: $mainFontSize2;
                line-height: 40px;
                color: $mainColor5;
            }
        }

        .intro-calendar-boxes {
            display: flex;
            gap: 35px;
        }
    }

    .intro-events {
        flex-basis: 340px;
    }

    .intro-box-today {
        width: 425px;
        height: 260px;;
        overflow: hidden;

        .date-box {
            left: auto;
            top: 0;
            right: 21px;
            padding: 14px 21px 10px;
            background-color: $mainColor5;

            .date-text {
                font-size: $mainFontSize4;
            }
        }
    }

    .intro-box-astro {
        width: 425px;
        height: 260px;
        flex-shrink: 0;

        .date-box {
            display: none;
        }

        .box-astro-head {
            display: flex;
            justify-content: space-between;
        }

        .box-astro-mini {
            height: 50px;
            width: 50px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: $mainColor4;
        }
        
        .box-astro-widgets {
            display: flex;
            column-gap: 13px;
            padding: 13px 21px 0 0;

            .text {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                font-size: $mainFontSize8;
                font-weight: bold;
            }
        }

        .astro-seasons {
            padding-top: 3px;
            position: relative;

            .icon-season {
                display: block;
                width: 34px;
                height: 34px;
                margin: 0 auto;
                background-repeat: no-repeat;
                background-size: 34px;
                background-position: center;
            }
        }

        .astro-zodiac {
            padding-top: 3px;
            position: relative;

            .icon-zodiac {
                display: block;
                width: 34px;
                height: 34px;
                margin: 0 auto;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }

        .astro-moon {
            padding: 5px;
            position: relative;
            
            .icon-moon {
                margin: 0 auto 3px;
                width: 28px;
                height: 28px;
            }
        }

        .today-info {
            display: none;
        }

        .actions-wrap {
            display: none;
        }
    }

    .intro-box-calendar {
        margin-top: 34px;
        height: 418px;
        max-width: 885px;

        .box-calendar-head {
            display: flex;
            justify-content: space-between;

            .box-calendar-button {
                display: flex;
                padding: 21px 21px 21px 0;
            }
        }
    }

    .intro-box-events {
        width: 340px;
        height: 499px;
        overflow: hidden;

        .box-title {
            padding-bottom: 13px;
        }

        .intro-to-events {
            margin: 13px 21px;
        }
        .main-button {
            margin: 0 auto;
        }
    }

    .intro-box-tools {
        width: 340px;
        margin-top: 34px;
        padding: 21px;
        box-sizing: border-box;

        .box-title {
            padding: 0 0 13px;
        }

        p {
            margin-bottom: 13px;
            font-size: $mainFontSize5;
            color: $mainColor5;
        }

        .main-button {
            margin: 0 auto;
        }
    }

    .today-info {
        padding: 21px;
        max-height: 137px;

        .intro-today-holiday {
            color: $mainColor2;
            font-size: $mainFontSize3;
            font-weight: bold;
            line-height: 1;
            text-align: center;

            span {
                display: block;
                margin-bottom: 8px;
            }
        }

        .intro-today-celebration {
            position: relative;
            color: $mainColor13;
            font-size: $mainFontSize4;
            font-weight: bold;
            line-height: 1;
            text-align: center;
        }

        .intro-today-nameday {
            margin: 13px 21px 0;
            font-size: $mainFontSize4;
            color: $mainColor13;
            font-weight: bold;
            text-align: center;

            .nameday-text {
                margin-right: 8px;
            }
        }
    }
}

.intro-calendar-content {
    padding: 0 21px 21px;

    .cal-item {
        .cal-item-head {
            display: none;
        }

        .cal-nav-title h2 span {
            font-size: $mainFontSize3;
            font-weight: 500;
            color: $mainColor5;
        }
        
        .cal-grid-row {
            .cal-date-head {
                height: 28px;
                grid-template-columns: 32px auto;
            }

            .cal-date-title {
                height: 26px;
                line-height: 26px;
            }

            .cal-ib-row-date {
                min-height: 47px;
                height: 47px;

                .cal-text-date {
                    margin: 3px 0 0;
                    line-height: unset;
                    text-align: center;
                    font-size: $mainFontSize5;
                }
            }
        }

        .cal-date-info {
            .icon {
                width: 15px;
                height: 15px;
            }

            .icon-holiday {
                width: 21px;
                height: 13px;
            }
        }
    }
}

@media screen and (max-width: 1390px) {
    .intro-content {
        width: 885px;
        flex-direction: column;
        padding: 34px;

        .intro-events {
            flex-grow: 1;
        }

        .intro-box-events {
            width: 100%;
            height: auto;
        }

        .intro-box-tools {
            width: 100%;
        }
    }
}

@media screen and (max-width: 970px) {
    .intro-content {
        width: auto;
    }
}

@media screen and (max-width: 860px) {
    #section-intro {
        padding-top: 55px;
    }

    .intro-content {
        padding: 21px;
        gap: 21px;

        .intro-calendar-head {
            display: none;
        }

        .intro-box-today {
            display: none;
        }

        .intro-box-astro {
            height: auto;
            width: auto;
            overflow: hidden;

            .box-title {
                visibility: hidden;
            }

            .date-box {
                display: block;
                left: 21px;
                right: auto;
                top: 0;
                padding: 14px 21px 10px;
                background-color: $mainColor5;
            }

            .today-info {
                display: block;
                padding: 0;
                overflow-y: auto;
                max-height: unset;

                .intro-today-holiday {
                    margin: 13px 21px 21px;
                    background-color: $mainColor12;
                    overflow-y: auto;

                    span {
                        margin: 8px;
                        color: #fff;
                    }
                }

                .intro-today-celebration {
                    margin: 13px 21px 21px;
                }

                .intro-today-nameday {
                    margin: 13px 21px 21px;
                }
            }

            .actions-wrap {
                display: block;
                padding: 0 13px 21px;
            }

            .cal-button,
            .sub-button {
                margin: 0 auto;
                width: 100%;
            }

            .cal-button {
                margin-bottom: 13px;
            }
        }

        .intro-box-events {
            .intro-to-events {
                margin: 13px 21px 21px;
            }

            .main-button {
                width: 100%;
            }
        }

        .intro-box-calendar {
            display: none;
        }

        .intro-calendar .intro-calendar-boxes {
            flex-direction: column;
        }
        
        .intro-box-tools {
            margin-top: 21px;

            .actions-wrap {
                .main-button {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: $mobileScreenWidth) {
    .intro-content {
        .intro-box-astro {
            .today-info {
                .intro-today-holiday {
                    margin: 5px 0 13px;
                }
            }

            .date-box {
                padding: 13px 13px 8px;
                left: 13px;

                .weekday-text {
                    font-size: $mainFontSize6;
                    padding-bottom: 3px;
                }

                .date-text {
                    font-size: $mainFontSize4;
                }
            }
        }

        .intro-box-tools {
            margin-top: 21px;
        }
    }
}

@media screen and (max-width: 510px) {
    .intro-content {
        .intro-box-astro .box-astro-widgets {
            padding: 8px 13px 0 0;
            column-gap: 8px;
        }
    }
}