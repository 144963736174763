//CALENDAR STYLES
body.body-full-screen {
    overflow-y: hidden;

    #calendar-container {
        background-color: darken($mainColor11, 8%);
    }
}

#calendar-wrap {
    width: 100%;
    overflow: auto;
    background-color: $mainColor4;
    background-image: url("../../../../../media/images/test_calendar_background.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.calendar-page {
    #site-header {
        position: static;
    }

    .main-content {
        margin-top: 0;
    }
}

#day-info {
    padding: 21px;
    background-color: #fff;
    box-sizing: border-box;
    display: none;
}

#calendar-container {
    min-height: 377px;
    margin: 0;
    padding: 0 34px 21px;
    position: relative;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.5);

    &.calendar-full-screen {
        height: 98%;

        .calendar-full-screen-btn {

            &:hover {
                right: 2px;
                width: 30px;
                height: 30px;
            }

            .fs-el-top-left::before, 
            .fs-el-top-right::before {
                top: 7px;
            }
    
            .fs-el-top-left::after,
            .fs-el-bottom-left::after {
                left: 7px;
            }
    
            .fs-el-top-right::after,
            .fs-el-bottom-right::after {
                right: 7px;
            }
    
            .fs-el-bottom-left::before,
            .fs-el-bottom-right::before {
                bottom: 7px;
            }
        }
    }
}

.calendar-react {
    max-width: 1597px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow-y: auto;

    .calendar-navigation {
        width: 100%;
        position: absolute;
        top: 21px;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        pointer-events: none;

        .cal-nav-btn {
            width: 34px;
            margin: 0 13px;
            padding: 0;
            opacity: 0.8;
            background-color: transparent;
            border: 0;
            outline: none;
            pointer-events: all;

            .icon-arrow {
                display: block;
                width: 34px;
                height: 55px;
            }
            
            &:not(.cal-nav-btn-disabled) {
                cursor: pointer;
            }
        }

        .cal-nav-btn-prev {
            &.cal-nav-btn-disabled {
                opacity: 0.2;
            }

            &:not(.cal-nav-btn-disabled):hover {
                opacity: 1;
            }
        }

        .cal-nav-btn-next {
            &:not(.cal-nav-btn-disabled):hover {
                opacity: 1;
            }
        }
    }

    .calendar-options {
        display: flex;
        justify-content: space-between;
        margin-top: 21px;

        .calendar-buttons {
            display: flex;
            column-gap: 21px;
        }

        .calendar-option-button {
            border: 0;
            padding: 0;
            transition: 300ms ease all;

            &.cal-settings-btn:hover {
                .icon {
                    transform: rotate(90deg);
                }
            }

            .icon {
                height: 100%;
                padding: 0 8px;
                background-size: 21px;
                transition: 300ms ease all;
            }

            .cal-btn-text {
                display: inline-block;
                border-left: 1px solid rgba(#fff, 0.5);
                padding-left: 13px;
                height: 100%;
                padding: 0 13px;
                line-height: 42px;
                vertical-align: middle;
                text-transform: uppercase;
            }
        }
    }

    .calendar-full-screen-btn {
        width: 34px;
        height: 34px;
        cursor: pointer;
        background-size: 100%;
        position: relative;

        &:hover {
            width: 38px;
            height: 38px;
        }

        .cal-fs-elem::before,
        .cal-fs-elem::after {
            content: "";
            display: block;
            position: absolute;
            background-color: $mainColor5;
            transition: all 0.5s;
        }

        .cal-fs-elem::before {
            width: 10px;
            height: 3px;
        }
        .cal-fs-elem::after {
            width: 3px;
            height: 10px;
        }

        .fs-el-top-left::before,
        .fs-el-top-left::after {
            top: 0;
            left: 0;
        }

        .fs-el-top-right::before,
        .fs-el-top-right::after {
            top: 0;
            right: 0;
        }

        .fs-el-bottom-left::before,
        .fs-el-bottom-left::after {
            bottom: 0;
            left: 0;
        }

        .fs-el-bottom-right::before,
        .fs-el-bottom-right::after {
            bottom: 0;
            right: 0;
        }
    }
    
    .popup-calendar-settings {
    
        .popup-content {

            .cal-settings-options {
                max-height: 640px;
                font-size: $mainFontSize5;
            }

            h3 {
                margin: 21px 0;
                font-size: $mainFontSize3;
            }
        }

        .cal-settings-category {
            margin-bottom: 13px;

            .cal-category-option {
                display: inline-block;
                width: 33%;
                margin-bottom: 13px;

                input {
                    margin: 0;
                    vertical-align: middle;
                }

                label {
                    margin-left: 8px;
                    vertical-align: middle;
                }
            }
        }

        .cal-settings-instruments {
            margin-bottom: 34px;

            .cal-instrument-option {
                margin-bottom: 21px;
            }

            .cal-opt-switch {
                position: relative;
                display: inline-block;
                width: 34px;
                height: 21px;
                vertical-align: middle;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked + .cal-opt-slider {
                        background-color: $mainColor2;
                    }

                    &:focus + .cal-opt-slider {
                        box-shadow: mainBoxShadow3;
                    }

                    &:checked + .cal-opt-slider::before {
                        -webkit-transform: translateX(13px);
                        -ms-transform: translateX(13px);
                        transform: translateX(13px);
                    }
                }

                .cal-opt-slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 34px;
                    background-color: #cccccc;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
    
                    &::before {
                        position: absolute;
                        content: "";
                        height: 13px;
                        width: 13px;
                        left: 4px;
                        bottom: 4px;
                        border-radius: 50%;
                        background-color: #FFFFFF;
                        -webkit-transition: 0.4s;
                        transition: 0.4s;
                    }
                }
            }

            .cal-opt-text {
                vertical-align: middle;
                margin-left: 13px;
            }
        }

        // .cal-settings-cities {
        //     .cal-city-option {
        //         margin-bottom: 8px;
        //     }
        // }

        // .cal-settings-organizations {
        //     padding-bottom: 34px;
        // }
    }
}

.calendar-content {
    position: relative;
}

// .popup-day-info {
//     .popup-content {
//         max-width: 540px;

//         .popup-day-info-row {
//             margin: 21px 0;
//         }

//         .popup-icon-holder {
//             display: inline-block;
//             width: 34px;
//             margin-right: 13px;
//         }

//         p {
//             display: inline-block;
//             max-width: 420px;
//             font-size: $mainFontSize4;
//             vertical-align: top;
//             margin-top: 3px;
//         }

//         img {
//             margin-right: 8px;
//             height: 19px;
//             border: 1px solid #000000;
//             border-radius: 50%;
//             vertical-align: bottom;
//         }
//     }
// }

.cal-item {
    display: none;
    overflow-y: auto;

    &.cal-item-active {
        display: block;
    }


    .cal-item-head {
        width: 100%;
        margin: 34px 0;
    }

    .cal-nav-title {
        line-height: 34px;
        text-align: center;
        font-size: $mainFontSize2;
        font-weight: bold;
        font-family: $mainFont2;

        h2 {
            margin: 0;

            span {
                text-transform: uppercase;
            }
        }
    }

    .cal-item-body {
        width: 100%;
        background-color: $mainColor6;

        .cal-item-body-grid {
            padding: 1px;
        }
    }

    .cal-grid-row {
        margin-bottom: 1px;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 1px;

        &:last-child {
            margin-bottom: 0;
        }

        .cal-date-title {
            height: 34px;
            line-height: 34px;
            font-size: $mainFontSize5;
            font-weight: bold;
            letter-spacing: 1px;
            text-align: center;
            text-transform: uppercase;
            overflow-x: hidden;
        }

        .cal-ib-row-date {
            min-height: 124px;
            max-height: 233px;
            position: relative;
            box-sizing: border-box;
            overflow-x: hidden;
            overflow-y: auto;
            pointer-events: none;

            &.cal-date-inactive {
                cursor: default;
                
                .cal-date-head {
                    border-bottom: 0;
                }
            }

            .cal-text-date {
                flex: 0 0 28px;
                font-size: $mainFontSize4;
                line-height: $mainFontSize4;
                margin-left: 8px;
                padding: 5px 0 0;
                color: $mainColor13;
            }

            .cal-date-info {
                display: inline-grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
                height: 100%;
            }

            .cal-event-item {
                display: flex;
                align-items: center;
                height: 21px;
                box-sizing: border-box;
                padding: 0 5px;
                overflow: hidden;
                word-break: break-word;
                pointer-events: all;

                &.hideCategory {
                    display: none;
                }

                &:first-child {
                    margin-top: 0;
                }

                .cal-event-item-dot {
                    flex: 0 0 8px;
                    height: 8px;
                    margin-right: 5px;
                    border-radius: 50%;
                }

                .cal-event-item-title {
                    padding: 0;
                    font-size: $mainFontSize8;
                    font-weight: normal;
                    line-height: 21px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-family: $mainFont2;
                }
            }
        }

        .cal-date-head {
            display: flex;
            justify-content: space-between;
            height: 28px;

            .hideInstrument {
                visibility: hidden;
            }
        }

        .cal-date-info-wrap {
            overflow-y: hidden;
            pointer-events: all;
        }
    }

    .icon {
        margin: 5px 8px 5px auto;
        width: 18px;
        height: 18px;
        cursor: pointer;
        overflow: hidden;
        display: block;
    }
    
    .icon-holiday {
        width: 26px;
        height: 16px;
    }
}

@media screen and (max-width: 960px) {
    #calendar-container {
        padding: 0;
        overflow-y: visible;

        .calendar-navigation {
            padding: 0 21px;
            box-sizing: border-box;

            .cal-nav-btn .icon-arrow {
                height: 34px;
                width: 34px;
            }
        }

        .calendar-content {
            padding: 0 21px;
        }

        .calendar-options {
            padding: 0 21px;
            margin: 21px 0;
        }

        .calendar-full-screen-btn {
            display: none;
        }
    }

    .cal-item {
        .cal-item-head {
            margin: 21px 0;
        }

        .cal-date-info .icon {
            width: 16px;
            height: 16px;
            margin: 4px 4px 0 auto;

            // &.icon-holiday {
            //     width: 21px;
            // }
        }

        .cal-item-body {

            .cal-grid-row-head {
                border-bottom: 1px solid rgba(255, 255, 255, 0.7);
                border-top: 1px solid rgba(255, 255, 255, 0.7);
            }

            .cal-date-head {
                height: 26px;
                border-bottom: 0;
                margin: 0;
                overflow-y: hidden;
            }

            .cal-ib-row-date {
                max-height: 144px;
                min-height: 89px;

                .cal-text-date {
                    margin: 0;
                    text-align: center;
                    font-size: $mainFontSize5;
                }
            }

            .cal-date-title,
            .cal-ib-row-date {
                background-color: transparent;
            }
        }
    }

    .calendar-page .cal-item .cal-item-body {
        background-color: transparent;
    }
}

@media screen and (max-width: 768px) {
    .cal-item {
        .cal-grid-row {
            .cal-ib-row-date {
                pointer-events: all;
                cursor: pointer;

                .cal-date-info-wrap,
                .cal-date-head {
                    pointer-events: none;
                }
            }
        }

        .cal-date-info {
            .icon {
                width: 11px;
                height: 11px;
                margin: 4px 2px 0 auto;
            }
        }

        .cal-nav-title {
            font-size: $mainFontSize3;
        }
    }

    #calendar-container {
        .calendar-content {
            padding: 0 8px;
        }

        .calendar-options {
            padding: 0 8px;
            margin: 13px 0 21px;
        }
    }
}

@media screen and (max-width: $mobileScreenWidth) {
    #calendar-container {
        .calendar-content {
            padding: 0 8px 21px;
            background-color: #fff;
        }
    }

    #day-info {
        display: block;
    }

    .cal-item .cal-grid-row .cal-ib-row-date {
        max-height: 89px;
        min-height: 55px;

        .cal-date-info-wrap {
            order: 1;
            // overflow-y: visible;
            // height: 0;
        }

        .cal-date-content {
            display: flex;
            max-width: 33px;
            width: fit-content;
            margin: 0 auto;
            pointer-events: none;
            overflow: hidden;
        }

        .cal-event-item {
            flex: 0 0 11px;
            height: 11px;
            padding: 0 3px;
            pointer-events: none;

            .cal-event-item-title {
                display: none;
            }
        }
    }

    .cal-item .cal-item-body {
        .cal-date-head {
            flex-direction: column;
            padding-top: 13px;
        }

        .cal-ib-row-date .cal-text-date {
            order: 2;
        }
    }
}



//THEME COLOURING
.theme-dark {
    #calendar-container {
        background-color: rgba(lighten(#000, 8%), 0.1);

        .calendar-options {
            .popup-calendar-settings {
                .popup-content {
                    background-color: $mainColor11;
                    color: #fff;
                }
            }
        }
    }

    .cal-item {
        .cal-item-head .cal-nav-title h2 {
            color: #fff;
        }

        .cal-item-body {
            background-color: $mainColor14; //rgba($mainColor14, 0.6);

            .cal-item-body-grid {
                padding: 0;
            }

            .cal-grid-row {
                .cal-date-weekend {
                    background-color: lighten($mainColor11, 8%);
                }

                .cal-date-off-day {
                    border: 3px solid $mainColor2;
                }
            }

            .cal-date-title {
                background-color: darken($mainColor11, 13%);
                color: #fff;
            }

            .cal-ib-row-date {
                background-color: $mainColor11;

                &.cal-date-today {
                    background-color: lighten($mainColor11, 34%);
                }
            }
        }
    }
}

.theme-light {

    .cal-item {
        .cal-item-head .cal-nav-title h2 {
            color: $mainColor14;
        }

        .cal-item-body {

            .cal-grid-row {
                .cal-date-weekend {
                    //background-color: darken(#fff, 5%);
                }

                .cal-date-off-day {
                    background-color: darken($mainColor3, 8%);
                    //background-color: darken($mainColor3, 13%);
                }
            }

            .cal-grid-row-head {
                background-color: #fff;
            }

            .cal-date-title {
                background-color: #fff;
                color: $mainColor13;
            }

            .cal-ib-row-date {
                background-color: #fff;
            }
        }
    }
}