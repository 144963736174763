.back-to-top-wrap {
    position: fixed;
    right: -55px;
    bottom: 55px;
    width: 55px;
    height: 55px;
    background-color: rgba($mainColor6, 0.8);
    overflow: hidden;
    transition: all 0.5s;

    &:hover {
        background-color: rgba($mainColor6, 1);
    }

    &.back-to-top-active {
        right: 34px;
    }

    button {
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        background-image: url("../../../../../media/images/i/arrow-top.svg");
        background-repeat: no-repeat;
        background-size: 21px;
        background-position: center;
        -webkit-animation: backToTopMove 2s infinite;
        animation: backToTopMove 2s infinite;
        animation-timing-function: ease-out;
    }
}


@-webkit-keyframes backToTopMove {
    0% {
        -webkit-transform: translate(0, -5px);
    }
    50% {
        -webkit-transform: translate(0, 2px);
    }
    100% {
        -webkit-transform: translate(0, -5px);
    }
}

@keyframes backToTopMove {
    0% {
        transform: translate(0, -5px);
    }
    50% {
        transform: translate(0, 2px);
    }
    100% {
        transform: translate(0, -5px);
    }
}