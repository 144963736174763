#section-application {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #547c9d;
    background-image: url("../../../../media/images/background/subscribers.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 34% center;
}

.application-content {
    position: relative;
    background-color: rgba($mainColor2, 0.4);

    .app-calendar {
        display: flex;
        min-height: 377px;

        .application-devices,
        .application-info {
            position: relative;
            width: 50%;
        }

        .app-info-head {
            width: 377px;
            padding-top: 70px;
            padding-left: 89px;
            color: #fff;

            h3 {
                font-size: $mainFontSize1;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            p {
                margin-top: 13px;
                font-size: $mainFontSize3;
                text-transform: uppercase;
            }

            .main-button {
                height: 44px;
                width: 233px;
                line-height: 42px;
                font-size: $mainFontSize4;
                margin-top: 34px;
                text-transform: uppercase;
            }
        }

        .device-holder {
            display: flex;
            align-items: flex-end;
            position: absolute;
            top: 34px;
            right: 8%;
        }
    
        .img-monitor {
            width: 377px;
            height: 294px;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0;
    
            .monitor-content {
                height: 192px;
                margin: 20px 23px;
                background-color: #fff;
            }
        }
    
        .img-phone {
            width: 105px;
            height: 197px;
            margin-left: -44px;
            background-image: url("../../../../media/images/i/mobile.svg");
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0;
    
            .phone-content {
                height: 188px;
                margin: 4px 5px;
                border-radius: 10px;
                background-color: #fff;
            }
        }

        .appear-scroll {
            .img-monitor {
                animation-name: showMonitor;
                animation-duration: 0.5s;
                animation-delay: 0.5s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        
            .img-phone {
                animation-name: showPhone;
                animation-duration: 0.5s;
                animation-delay: 1.5s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }

    .popup-application-info {
        .icon-application {
            display: block;
            width: 144px;
            height: 144px;
            margin: 34px auto;
        }

        .popup-title {
            margin: 21px 55px;
        }
    }
}

@keyframes showMonitor {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes showPhone {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@media screen and (max-width: 1280px) {
    .app-calendar {
        .device-holder {
            right: 13%;
        }

        .img-monitor {
            width: 377px;
            height: 294px;

            .monitor-content {
                height: 190px;
                margin: 21px 25px;
            }
        }

        .img-phone {
            width: 89px;
            height: 167px;

            .phone-content {
                height: 161px;
                margin: 3px 4px;
            }
        }
    }
}