.add-event-form-wrap {
    width: 100%;
    max-width: $largeScreenWidth;
    margin: 0 auto;
    padding: 0 144px 34px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;

    form {
        width: 100%;
        box-sizing: border-box;
        padding: 55px;
        background-color: $mainColor14;
        border-radius: 8px;
    }

    .add-event-form-row {
        padding: 21px 0;

        .add-event-form-col {
            display: inline-block;
            width: 50%;
        }
    }

    .add-event-form-item {
        box-sizing: border-box;
        padding: 0 21px;
        font-size: $mainFontSize4;

        label {
            display: block;
            color: #fff;
            padding-bottom: 13px;
        }

        input,
        textarea,
        select {
            width: 100%;
        }

        select {
            height: 40px;
            padding: 8px;
        }

        textarea {
            display: block;
            min-width: 100%;
            max-width: 100%;
            height: 144px;
            min-height: 55px;
            max-height: 377px;
        }

        button {
            width: 100%;
        }
    }

    .add-event-form-item {
        
    }
}