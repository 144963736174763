.content-wrap .theme-dark {
    background-color: $mainColor11;
}

:not(.home-page).theme-dark #site-header {
    background-color: $mainColor14;
}

.theme-dark {
    #site-header {
        background-color: transparent; /*$mainColor11;*/
    }

    .header-inner {
        .theme-text {
            color: #fff;
        }

        .theme-slider {
            background-color: $mainColor11;
        }

        .h-logo #main-logo {
            background-image: url("../../../media/images/chronos-logo-text-alt.svg");
        }
    }

    .section-title h2 {
        color: #fff;

        &::after {
            border-bottom: 1px solid #fff;
        }
    }

    #section-summary {
        background-color: $mainColor14;
    }

    .summary-content {
        .scene {
            background-image: url("../../../media/images/scene-alt.png");
        }
    }

    #section-donate {
        background-color: $mainColor11; //$mainColor14;
    }

    .donate-content {
        .dc-info-text {
            color: #fff;
        }

        .dc-payment {
            background-color: $mainColor14;

            .dc-payment-title {
                color: #fff;
            }

        }

        .dc-bank-number .row-bank-num {
            color: $mainColor3;
        }
    }
    
    #section-partners-list {
        background-color: $mainColor11;

        .partners-list-contact {
            p {
                color: #fff;
            }
        }
    }

    .popup-year-holidays {
        .popup-content {
            background-color: $mainColor13;
            color: #fff;
        }

        .calendar-year-holidays .cal-yh-month-content {
            background-color: $mainColor10;
            color: $mainColor11;
        }
    }

    .om-text {
        color: #fff;
    }

    .page-title {
        color: #fff;

        h1::after {
            border-bottom: 1px solid #fff;
        }
    }

    .events-wrap {
        background-color: $mainColor11;
    }

    .events-react {
        //background-color: $mainColor11;

        .events-filter-wrap {
            border-color: darken($mainColor11, 8%);;
            background-color: darken($mainColor11, 8%); //10, 11, 13
        }

        .events-filter-form {
            .events-filter-option .events-filter-option-selector {
                color: #fff;
            }
            
            .events-dropdown-arrow::after {
                border-top-color: #fff;
            }
        }
    }

    .sidebar-content {
        .sidebar-error {
            color: $mainColor3;
        }
    }

    .contacts-wrap {
        background-color: $mainColor11;
    }

    .contacts-container {
        .contacts-column-left {
            color: #fff;

            .contacts-form-item {
                input, textarea {
                    border: none;
                }
            }
        }

        .secondary-title h3::after {
            border-bottom: 1px solid #fff;
        }

        .contacts-column-right {
            background-color: $mainColor14;
        }
    }

    .faquestions-wrap,
    .section-faquestions {
        background-color: $mainColor11;
    }

    .add-event-wrap {
        background-color: $mainColor11;
    }

    .add-event-form-wrap form {
        background-color: $mainColor14;
    }
}