/* === SIZES === */
$largeScreenWidth: 1280px;
$mobileScreenWidth: 640px;
$minScreenWidth: 360px;

/* === FONTS === */
$mainFont1: "Azbuki", sans-serif;
$mainFont2: "Roboto Condensed", sans-serif;

$mainFontSize1: 3.4rem;
$mainFontSize2: 2.1rem;
$mainFontSize3: 1.6rem;
$mainFontSize4: 1.3rem;
$mainFontSize5: 1.1618rem;
$mainFontSize6: 1.1rem;
$mainFontSize7: 1rem;
$mainFontSize8: 0.75rem;

/* === COLORS === */
$mainColor1: #e99059;
$mainColor2: #5990e9;
$mainColor1Hover: darken($mainColor1, 8%);
$mainColor2Hover: darken($mainColor2, 8%);

/* Color schema 1 */
$mainColor3: #fdf5e6;
$mainColor4: #e6edf5;
$mainColor5: #405360;
$mainColor6: #eeeeee;
$mainColor7: #3e7aa3;
$mainColor8: #d8efff;
$mainColor9: #b0dfff;
$mainColor10: #EAF0F4;
$mainColor11: #2E384D;
$mainColor12: #87B4D7;
$mainColor13: #505050;
$mainColor14: #1C2023;

$mainColorPhi: #112358;

$colorDay: #DB901C;
$colorNight: #0B1E38;

$colorPositive: #2CC194;
$colorNegative: #ff0000;
/*
=== FIB NUMS COLORS ===
 - rgb(89, 144, 233) - #5990e9 (blue)
 - rgb(233, 144, 89) - #e99059 (orange)
 - not used - rgb(89, 233, 144) - #58e990 (green)
 - not used - rgb(144, 233, 89) - #90e958 (green)
 - not used - rgb(233, 89, 144) - #e95990 (pink)
 - not used - rgb(144, 89, 233) - #9059e9 (purple)
*/


$mainBoxShadow1: 0 3px 5px 0 rgba(0, 0, 0, 0.21);
$mainBoxShadow2: #bbb 0 7px 2px -7px inset;
$mainBoxShadow3: 0 0 1px $mainColor2;