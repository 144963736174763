html {
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

* {
    -webkit-font-smoothing: subpixel-antialiased;
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
  
div, span, header, footer, section {
    margin: 0;
    padding: 0;
    border: 0;
}
  
button:focus {
    outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font: inherit;
}

p {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}